import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  flex: 1;

  width: 100%;
  padding: 32px 16px;

  // background-image: url('/images/group-pancake.svg');
  // background-repeat: no-repeat;
  // background-position: bottom 24px center;
  // background-size: 90%;

  ${({ theme }) => theme.mediaQueries.xs} {
    // background-size: auto;
    // background-image: url('/images/swap-bg-${({ theme }) => (theme.isDark ? 'dark' : 'light')}.png');
    // background-size: cover;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    // background-image: url('/images/swap-bg-${({ theme }) => (theme.isDark ? 'dark' : 'light')}.png'),
    // url('/images/coin-bottom.svg');

    // background-image: url('/images/arch-${({ theme }) => (theme.isDark ? 'dark' : 'light')}.svg'),
    //   url('/images/left-pancake.svg'), url('/images/right-pancake.svg');
    // background-repeat: no-repeat;
    background-position: center 10px, 50% 550px;
    background-size: cover, 256px;
    min-height: 90vh;
  }
`

export default Container
