import React, { ReactNode, MouseEvent, useState } from 'react'
import styled from 'styled-components'
import { Heading, IconButton, Text, Flex, useModal, TuneIcon, HistoryIcon } from '@pancakeswap-libs/uikit'
import { ethers } from "ethers";

declare const window: any

const StyledPageHeader = styled.div`
  // border-bottom: 0px solid ${({ theme }) => theme.colors.borderColor};
  // padding: 24px;
  // background-image: ${({ theme }) => theme.colors.gradients.swapHeader};
  // background-color: red;
  width: 600;
`

const Details = styled.div`
  display: flex;
  justify-content: space-around;
  width: 400px;
  margin-bottom: 10px;
  // padding-left: 10px;
  // padding-right: 10px;
`

const BitkubContainer = styled.div`
  width: 133px;
`

const BitkubRPC = styled.img `
  cursor: pointer;
  margin-bottom: 20px;
  width: 133;
  // height: 35;
`

const CustomImage = styled.img `

  margin-bottom: 20px;
  cursor: pointer;
  height: 45px;
  width: 43px;
`

const Title = styled.div `
	color: white;
	margin-bottom: 30px;
`

// const [log, setLog] = useState<string[]>([])

const addNetwork = (params: any) =>
    window.ethereum.request({ method: 'wallet_addEthereumChain', params })
      .then(() => {
        // setLog([...log, `Switched to ${params[0].chainName} (${parseInt(params[0].chainId)})`])
        // setChainId(parseInt(params[0].chainId))
      })

const addKubRPC = () =>
    addNetwork([
      {
        chainId: '0x60',
        chainName: 'Bitkub Chain',
        nativeCurrency: {
          name: 'KUB',
          symbol: 'KUB',
          decimals: 18
        },
        rpcUrls: ['https://rpc.bitkubchain.io'],
        blockExplorerUrls: ['https://bkcscan.com']
      }
    ])

const addToken = (params: any) =>
    window.ethereum.request({ method: 'wallet_watchAsset', params })
      .then(() => { 
      	// setLog([...log, 'Success, Token added!']))
  		})
      // .catch((error: Error) => setLog([...log, `Error: ${error.message}`]))


const addLumi = () =>
	addToken({
	  type: 'ERC20',
	  options: {
	    address: '0x95013Dcb6A561e6C003AED9C43Fb8B64008aA361',
	    symbol: 'LUMI',
	    decimals: 18,
	    image: 'https://exchange.diamon.finance/images/coins/0x95013Dcb6A561e6C003AED9C43Fb8B64008aA361.png'
	  }
	})

const addKKub = () =>
	addToken({
	  type: 'ERC20',
	  options: {
	    address: '0x67eBD850304c70d983B2d1b93ea79c7CD6c3F6b5',
	    symbol: 'KKUB',
	    decimals: 18,
	    image: 'https://exchange.diamon.finance/images/coins/kub.png'
	  }
	})

const addKUSDT = () =>
	addToken({
	  type: 'ERC20',
	  options: {
	    address: '0x7d984C24d2499D840eB3b7016077164e15E5faA6',
	    symbol: 'KUSDT',
	    decimals: 18,
	    image: 'https://exchange.diamon.finance/images/coins/0x7d984C24d2499D840eB3b7016077164e15E5faA6.png'
	  }
	})

const addLorem = () =>
	addToken({
	  type: 'ERC20',
	  options: {
	    address: '0x42A7ECC6D2D2780C2C2daAa8F7f2d2C2557e276b',
	    symbol: 'Lorem',
	    decimals: 18,
	    image: 'https://diamon.finance/defi/lorem-token.png'
	  }
	})

const addKUSDC = () =>
  addToken({
    type: 'ERC20',
    options: {
      address: '0x77071ad51ca93fc90e77BCdECE5aa6F1B40fcb21',
      symbol: 'KUSDC',
      decimals: 18,
      image: 'https://exchange.diamon.finance/images/coins/0x77071ad51ca93fc90e77BCdECE5aa6F1B40fcb21.png'
    }
  })

const addYES = () =>
  addToken({
    type: 'ERC20',
    options: {
      address: '0x8debbb77e8a17cffCfC0C7F1f40308886edD3f9f',
      symbol: 'YES',
      decimals: 18,
      image: 'https://exchange.diamon.finance/images/coins/0x8debbb77e8a17cffCfC0C7F1f40308886edD3f9f.png'
    }
  })

const addPunk = () =>
  addToken({
    type: 'ERC20',
    options: {
      address: '0x73bf290552dD808D58e459406D2B9748d2fDc680',
      symbol: 'PUNK',
      decimals: 18,
      image: 'https://app.diamon.finance/images/coins/0x73bf290552dD808D58e459406D2B9748d2fDc680.png'
    }
  })

const MetaMask = () => {

	
  return (
  	<>
  		<Title>
          	Add token to MetaMask
        </Title>
        <BitkubContainer>
          <BitkubRPC src="/diamon/metamask/bitkub-chain.png" alt="Bitkub Chain"  onClick={addKubRPC} />
        </BitkubContainer>

        <Details>
          <CustomImage src="/diamon/metamask/kubcoin.png" alt="Moon Village" onClick={addKKub}/>
          <CustomImage src="/diamon/metamask/lumi.png" alt="Moon Village" onClick={addLumi}/>
          <CustomImage src="/diamon/metamask/tether.png" alt="USDT" onClick={addKUSDT}/>
          <CustomImage src="/diamon/metamask/usdc.png" alt="USDC" onClick={addKUSDC}/>
          <CustomImage src="/diamon/metamask/yes.png" alt="YUEMMAI" onClick={addYES}/>
          {/* <CustomImage src="/diamon/metamask/punk.png" alt="Punkkub" onClick={addPunk}/> */}
        </Details>
    </>
  )
}

export default MetaMask
