import { Token, TokenAmount } from '@pancakeswap-libs/sdk'
import { useMemo } from 'react'

import { useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

export function useTokenAllowance(token?: Token, owner?: string, spender?: string): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

// console.log(token?.symbol);
  const inputs = useMemo(() => [owner, spender], [owner, spender])
  let allowABI;
  if(token?.symbol === "KUSDT" || token?.symbol === "KBTC" || token?.symbol === 'dBTC') allowABI = 'allowances' 
  else allowABI = 'allowance';

  const allowance = useSingleCallResult(contract, allowABI, inputs).result 
//  else
 // const allowance = useSingleCallResult(contract, 'allowance', inputs).result 


  return useMemo(() => (token && allowance ? new TokenAmount(token, allowance.toString()) : undefined), [
    token,
    allowance,
  ])
}

export default useTokenAllowance
