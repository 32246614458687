import React from 'react'
import { Button, ButtonProps, useWalletModal} from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'

const UnlockButton: React.FC<ButtonProps> = (props) => {
  const TranslateString = useI18n()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  const UButton = styled(Button)`
  	width: 100%;
  	color: #FFFFFF;
  	background: linear-gradient(48deg, rgba(2,0,36,1) 0%, rgba(85,159,218,1) 0%, rgba(0,87,185,1) 99%);
  `
  console.log(props);

  return (
  	<div>
  		<UButton onClick={onPresentConnectModal}>
  			{TranslateString(292, 'Unlock Wallet')}
  		</UButton>
    </div>
  )
}

export default UnlockButton

// <Button onClick={onPresentConnectModal} {...props}>
//     {TranslateString(292, 'Unlock Wallet')}
// </Button>