import { Currency, currencyEquals, ETHER, WETH } from '@pancakeswap-libs/sdk'
import { useMemo } from 'react'
import { tryParseAmount } from '../state/swap/hooks'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useCurrencyBalance } from '../state/wallet/hooks'
import { useActiveWeb3React } from './index'
import { useWETHContract,useDTokenContact } from './useContract'

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
  PEG,
  UNPEG
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  typedValue: string | undefined
): { wrapType: WrapType; execute?: undefined | (() => Promise<void>); inputError?: string } {
  const { chainId, account } = useActiveWeb3React()
  const wethContract = useWETHContract()
  const dtokenComtract = useDTokenContact();
  const balance = useCurrencyBalance(account ?? undefined, inputCurrency)
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency), [inputCurrency, typedValue])
  const addTransaction = useTransactionAdder()
  let inputDSWAP = false;
  let outputDSWAP = false;
  let dswap =false;
//  switch (inputCurrency.symbol){
 //   case 'dBUSD':
 // }
   if(inputCurrency !== undefined){
       if(inputCurrency.symbol === 'dBUSD' || inputCurrency.symbol === 'dBTC' || 
       inputCurrency.symbol === 'KBTC' || inputCurrency.symbol === 'KUSDT' ||
       inputCurrency.symbol === 'YUSD'
       ) inputDSWAP = true;
   }
   if(outputCurrency !== undefined){
       if(outputCurrency.symbol === 'dBUSD' || outputCurrency.symbol === 'dBTC' || 
       outputCurrency.symbol === 'KBTC' || outputCurrency.symbol === 'KUSDT' ||
       outputCurrency.symbol === 'YUSD'
       ) outputDSWAP = true;
   }
  
   if(inputDSWAP === true && outputDSWAP === true)
   {
    if(inputCurrency !== undefined && outputCurrency !== undefined){

      if(inputCurrency.symbol === 'dBUSD' && outputCurrency.symbol === 'KUSDT')
            dswap = true;
      if(inputCurrency.symbol === 'KUSDT' && outputCurrency.symbol === 'dBUSD')
            dswap = true;
      if(inputCurrency.symbol === 'dBTC' && outputCurrency.symbol === 'KBTC')
            dswap = true;
      if(inputCurrency.symbol === 'KBTC' && outputCurrency.symbol === 'dBTC')
            dswap = true;


      if(inputCurrency.symbol === 'YUSD' && outputCurrency.symbol === 'KUSDT')
            dswap = true;
      if(inputCurrency.symbol === 'KUSDT' && outputCurrency.symbol === 'YUSD')
        dswap = true;

    }
   }
  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency || !dtokenComtract) return NOT_APPLICABLE

    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)
    
    if(dswap) {
      // // let add = ''

      // if(inputCurrency !== undefined){
      //   console.log(70, inputCurrency.name)
      //   console.log(71, inputCurrency.address)
      //   // add =inputCurrency.address;
      // }
      

       return {
        wrapType: WrapType.PEG,
        execute:
          sufficientBalance && inputAmount
            ? async () => { 
                try {
//                  const txReceipt = await wethContract.deposit({ value: `0x${inputAmount.raw.toString(16)}` })
                  const txReceipt = await dtokenComtract.swapTokenWithToken(inputCurrency.address,outputCurrency.address,`0x${inputAmount.raw.toString(16)}`)
                  addTransaction(txReceipt, { summary: `SWAP ${inputAmount.toSignificant(6)} ${inputCurrency.symbol} to ${outputCurrency.symbol}` })
                } catch (error) {
                  console.error('Could not swap', error)
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : 'Insufficient balance'
      }
    }
    if (inputCurrency === ETHER && currencyEquals(WETH[chainId], outputCurrency)) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => { 
                try {
                  const txReceipt = await wethContract.deposit({ value: `0x${inputAmount.raw.toString(16)}` })
                  addTransaction(txReceipt, { summary: `Wrap ${inputAmount.toSignificant(6)} KUB to KKUB` })
                } catch (error) {
                  console.error('Could not deposit', error)
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : 'Insufficient KUB balance'
      }
    } if (currencyEquals(WETH[chainId], inputCurrency) && outputCurrency === ETHER) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  const txReceipt = await wethContract.withdraw(`0x${inputAmount.raw.toString(16)}`)
                  addTransaction(txReceipt, { summary: `Unwrap ${inputAmount.toSignificant(6)} KKUB to KUB` })
                } catch (error) {
                  console.error('Could not withdraw', error)
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : 'Insufficient KKUB balance'
      }
    } 
      return NOT_APPLICABLE
  }, [wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction,dswap,dtokenComtract])
}
